import { getFilenameFromUrl } from './string';
import JSZip from 'jszip';

export function sanitizeFileName(file: File) {
  // file name will only contain alphanumeric & _
  const filenameChunks = file.name.split('.');
  const extension = filenameChunks.pop();
  const filename = filenameChunks
    .splice(0, filenameChunks.length)
    .join()
    .replace(/[^A-Z0-9]+/gi, '_');

  return new File([file], `${filename}.${extension}`, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export const downloadAs = (url: string, filename: string | null = null) =>
  fetch(url, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute(
        'download',
        filename || (getFilenameFromUrl(url) as string),
      );

      // Append to html link element page
      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);
      return filename;
    });

export function formatBytes(bytes: any, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function arrayToCSV(data: any, delimiter = ',', headers: any) {
  if (!Array.isArray(data) || data.length === 0) {
    return '';
  }

  const csvRows = data.map((row) => {
    return headers
      .map((header: any) => {
        const cell =
          row[header.key] === null || row[header.key] === undefined
            ? ''
            : row[header.key];
        // Escape double quotes in values by doubling them
        return `"${String(cell).replace(/"/g, '""')}"`;
      })
      .join(delimiter);
  });

  // Combine headers and rows into a single CSV string
  return [headers.map((head: any) => head.title), ...csvRows].join('\n');
}

export async function downloadUrlsAsZip(
  files: Array<object>,
  folderName: string,
) {
  // Import JSZip library (make sure to include it in your project)
  const zip = new JSZip();
  const clipFolder = zip.folder(folderName);

  // Array to store download promises
  const downloadPromises = files.map(async (file) => {
    try {
      // Destructure url and name from the file object
      const { url, name } = file;

      // Fetch the file
      const response = await fetch(url);

      // Check if the fetch was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      }

      // Get the blob
      const blob = await response.blob();

      // Determine file extension from URL
      const extension = getFileExtension(url);

      // Combine name with extension
      const filenameWithExtension = `${name}${extension}`;

      // Add file to the Clips folder in the ZIP
      clipFolder.file(filenameWithExtension, blob);
    } catch (error) {
      console.error(`Error downloading ${file.url}:`, error);
    }
  });

  // Wait for all downloads to complete
  await Promise.all(downloadPromises);

  // Generate the ZIP file
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  // Create a download link and trigger download
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(zipBlob);
  downloadLink.download = `${folderName}.zip`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

// Helper function to get file extension from URL
function getFileExtension(url) {
  try {
    // Extract extension from URL path
    const urlPath = new URL(url).pathname;
    const match = urlPath.match(/\.([0-9a-z]+)$/i);

    if (match) {
      return match[0]; // Returns with the dot, e.g. '.jpg'
    }

    // If no extension found, use content type guessing
    return '.bin';
  } catch (error) {
    // Fallback if URL parsing fails
    return '.bin';
  }
}
